import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'

import { STOCK_LIST } from '../../utils/stockList'
import iconmonstrArrow65 from '../../assets/svg/iconmonstr-arrow-65.svg'
import useComponentVisible from './useComponentVisible'
import { LOTTO_GROUP_ENG, lottoType, lottoType_TH } from '../../utils/lottoList'
import { getLang } from '../../redux/selectors/lang'
/*API FORMAT
  result: {
    lotto2a: "56"
    lotto2b: "32"
    lotto3a: "456"
    lotto3b: ""
    lotto3f: ""
    lotto6: ""
  }
*/

export default ({ _id, title = 'หวย XXX', src = null, result = {}, createdAt }) => {
  const { has2a = false, has2b = true, has3a = true, has3b = false, has3f = false, has6 = false } =
    {
      หวยรัฐบาลไทย: {
        has2a: false,
        has2b: true,
        has3a: false,
        has3b: true,
        has3f: true,
        has6: true,
      },
      หวยออมสิน: {
        has2a: false,
        has2b: true,
        has3a: true,
        has3b: false,
        has3f: false,
        has6: true,
      },
      'หวยธกส.': {
        has2a: false,
        has2b: true,
        has3a: true,
        has3b: false,
        has3f: false,
        has6: true,
      },
    }[title] || {}
  const [isCollapse, setIsCollapse] = useState(true)
  // const [lottoData, setLottoData] = useState([])
  // const [lottoName, setLottoName] = useState('')
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true)
  const lang = useSelector(getLang)
  const language = lang.lang

  useEffect(() => {
    if (isComponentVisible === false) {
      setIsCollapse(true)
    }
    setIsComponentVisible(true)
  }, [isComponentVisible])
  // useEffect(() => {
  //   if (lottoName === '') {
  //     return
  //   }
  //   API.get('lotto', `/results?limit=10&status=rewarded&lottoName=${lottoName}`)
  //     .then((res) => {
  //       const lottoData = res.detail
  //       console.log(lottoData, 'lottoData')
  //       setLottoData(lottoData)
  //     })
  //     .catch((err) => console.error(err))
  //   return () => {
  //     // console.log('return in useEffect')
  //   }
  // }, [lottoName])
  const dateNow = (date) => {
    // console.log(date, 'date')
    if (date !== undefined) {
      const dateString = date.substr(3, 2) + '/' + date.substr(0, 2) + '/' + date.substr(6, 4)
      return new Date(dateString).toLocaleDateString('en-UK', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    }
    return new Date().toLocaleDateString('en-UK', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  return (
    <div className={`home__lotto ${title === 'หวย XXX' ? 'hidden' : ''}`}>
      <div className='home__dong_lotto-title'>
        <div className='dong_result_title'>
          <h1>{LOTTO_GROUP_ENG[title]}</h1>
           <p style={{ paddingBottom: '5px', textShadow: 'none' }}>{dateNow(createdAt)}</p>
          {/*<p style={{ paddingBottom: '5px', textShadow: 'none' }}>1 April 2023</p>*/}
        </div>

        <div className='dong_winning_6digit_result'>
          <div className='dong_winning_6digit_box'>
            <div className='text_head'>รางวัลที่หนึ่ง</div>
            {/*<div className='text_digit'>XXXXXX</div>*/}
            <div className='text_digit'>{result.lotto6 ? result.lotto6 : '807779'}</div>
          </div>

          <div className='dong_winning_3fdigit_result'>
            <div className='dong_winning_3digit_box'>
              <div className='text_head'>{language === 'MM' ? lottoType['3A'] : lottoType_TH['3A']}</div>
              {/*<div className='text_digit'>XXX</div>*/}
              <div className='text_digit'>{result.lotto3a ? result.lotto3a : 'XXX'}</div>
            </div>

            <div className='dong_winning_3digit_box'>
              <div className='text_head'>{language === 'MM' ? lottoType['2A'] : lottoType_TH['2A']}</div>
              {/*<div className='text_digit'>XX</div>*/}
              <div className='text_digit'>{result?.lotto2a || 'XX'}</div>
            </div>

            <div className='dong_winning_3digit_box'>
              <div className='text_head'>{language === 'MM' ? lottoType['2B'] : lottoType_TH['2B']}</div>
              {/*<div className='text_digit'>XX</div>*/}
              <div className='text_digit'>{result?.lotto2b || 'XX'}</div>
            </div>
          </div>

          <div className='dong_winning_3bdigit_result'>
            <div className='dong_winning_3digit_box'>
              <div className='text_head'>{language === 'MM' ? lottoType['3F'] : lottoType_TH['3F']}</div>
              {/*<div className='text_digit'>XXX</div>*/}
              <div className='text_digit'>{result.lotto3f ? result.lotto3f.split(' ')[0] : 'XXX'}</div>
            </div>
            <div className='dong_winning_3digit_box'>
              <div className='text_head'>{language === 'MM' ? lottoType['3F'] : lottoType_TH['3F']}</div>
              {/*<div className='text_digit'>XXX</div>*/}
              <div className='text_digit'>{result.lotto3f ? result.lotto3f.split(' ')[1] : 'XXX'}</div>
            </div>
            <div className='dong_winning_3digit_box'>
              <div className='text_head'>{language === 'MM' ? lottoType['3B'] : lottoType_TH['3B']}</div>
              {/*<div className='text_digit'>XXX</div>*/}
              <div className='text_digit'>{result.lotto3b ? result.lotto3b.split(' ')[0] : 'XXX'}</div>
              {/* <div className='text_digit'>{'544'}</div> */}
            </div>

            <div className='dong_winning_3digit_box'>
              <div className='text_head'>{language === 'MM' ? lottoType['3B'] : lottoType_TH['3B']}</div>
              {/*<div className='text_digit'>XXX</div>*/}
              <div className='text_digit'>{result.lotto3b ? result.lotto3b.split(' ')[1] : 'XXX'}</div>
            </div>
          </div>
        </div>
        {/* <div className='two-column'> */}

        {/* {has3f && (
            <div className='home__lotto-box'>
              <div className='text-xl'>สามตัวหน้า</div>
              <div>{result.lotto3f ? result.lotto3f.replace(' ', ',') : 'XXX'}</div>
            </div>
          )}
          {has3a && (
            <div className='home__lotto-box '>
              <div className='text-xl'>3ตัวบน</div>
              <div>{result?.lotto3a || 'XXX'}</div>
            </div>
          )}
          {has3b && (
            <div className='home__lotto-box '>
              <div className='text-xl'>สามตัวล่าง</div>
              <div>{result.lotto3b ? result.lotto3b.replace(' ', ',') : 'XXX'}</div>
            </div>
          )}
          {has2a && (
            <div className='home__lotto-box '>
              <div className='text-xl'>สองตัวบน</div>
              <div>{result?.lotto2a || 'XX'}</div>
            </div>
          )}
          {has2b && (
            <div className='home__lotto-box '>
              <div className='text-xl'>2ตัวล่าง</div>
              <div>{result?.lotto2b || 'XX'}</div>
            </div>
          )} */}

        {/* </div> */}
      </div>

      {/*{lottoData.map((lotto, index) => {*/}
      {/*  const isHide = isCollapse*/}
      {/*  return (*/}
      {/*    <div*/}
      {/*      ref={ref}*/}
      {/*      className={`home__detailsBox-purchaseDetails ${isHide ? 'hide' : ''}`}*/}
      {/*      key={index}*/}
      {/*      style={{*/}
      {/*        borderBottom: isHide ? '' : '2px solid #dddddd',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <div className='home__detailsBox-purchaseDetails-detail'>*/}
      {/*        <span className='home__detailsBox-purchaseDetails-amount-title' style={{ fontSize: '15px' }}>*/}
      {/*          {dateNow(lotto.drawDateStr)}*/}
      {/*          /!*{new Date(`20${lotto.drawDateStr.split('/').reverse().join(',')}`).toLocaleDateString('th-TH', {*!/*/}
      {/*          /!*  year: '2-digit',*!/*/}
      {/*          /!*  month: 'short',*!/*/}
      {/*          /!*  day: 'numeric',*!/*/}
      {/*          /!*  weekday: 'long',*!/*/}
      {/*          /!*}) || ''}*!/*/}
      {/*        </span>*/}
      {/*      </div>*/}
      {/*      <div className='home__detailsBox-purchaseDetails-amount'>*/}
      {/*        <span className='home__detailsBox-purchaseDetails-amount-title'>{lotto.results['3A']}</span>*/}
      {/*      </div>*/}
      {/*      <div className='home__detailsBox-purchaseDetails-amount'>*/}
      {/*        <span className='home__detailsBox-purchaseDetails-amount-title'>{lotto.results['2B']}</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  )*/}
      {/*})}*/}
    </div>
  )
}
